<template>
  <div class="background blue-ex">
    <div class="content">
      <div class="preambule text white">
        <p>{{ $t('preambule.preambule_text') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreambuleComp'
}
</script>

<style scoped>
.preambule {
  padding: 2rem 0;
}

.preambule p {
  font-weight: normal;
  text-align: center;
}
</style>