<template>
  <div class="background two-color-blue-grey">
    <div class="content">
      <div class="container">
        <div class="button">
          <h1>{{ $t('service.offer') }}</h1>
          <p>{{ $t('service.service') }}</p>
          <div class="segment-button" 
            :class="{ selected: type === 'mobile'}"
            @click="type='mobile'">{{ $t('service.service_mobile') }}</div>
          <div class="segment-button" 
            :class="{ selected: type === 'web'}"
            @click="type='web'">{{ $t('service.service_web') }}</div>
          <div class="segment-button" 
            :class="{ selected: type === 'management'}"
            @click="type='management'">{{ $t('service.service_management') }}</div>
          <div class="segment-button" 
            :class="{ selected: type === 'consulting'}"
            @click="type='consulting'">{{ $t('service.service_consult') }}</div>
          <div class="segment-button" 
            :class="{ selected: type === 'iot'}"
            @click="type='iot'">{{ $t('service.service_iot') }}</div>
        </div>
        <div class="data">
          <div v-if="type=='mobile'">
            <img src="../assets/img/mobile-desain.jpg">
            <p>{{ $t('service.mobile_greet_1') }}</p>
            <p>{{ $t('service.mobile_greet_2') }}</p>
            <ul>
              <li>Flutter</li>
              <li>Java/Kotlin</li>
              <li>Swift</li>
              <li>C++/C#</li>
            </ul>
          </div>
          <div v-else-if="type=='web'">
            <img src="../assets/img/web-desain.jpg">
            <p>{{ $t('service.web_greet_1') }}</p>
            <p>{{ $t('service.web_greet_2') }}</p>
          </div>
          <div v-else-if="type=='management'">
            <img src="../assets/img/management-system.jpg">
            <p>{{ $t('service.management_greet_1') }}</p>
          </div>
          <div v-else-if="type=='consulting'">
            <img src="../assets/img/consult.jpg">
            <p>{{ $t('service.consult_greet_1') }}</p>
          </div>
          <div v-else-if="type=='iot'">
            <img src="../assets/img/iot.jpg">
            <p>{{ $t('service.iot_greet_1') }}</p>
            <p>{{ $t('service.iot_greet_2') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ServiceComp',
  data() {
    return {
      type: 'mobile'
    }
  }
}
</script>
<style scoped>
.container {
  display: flex;
}
.button {
  width: 50rem;
  padding: 5rem 0;
  padding-left: 4rem;
  padding-right: 7rem;
  box-sizing: content-box;
  color: white;
}

.button .segment-button {
  padding: 1rem 2rem;
  margin-bottom: 1rem;
  border: 0.2rem solid white;
  border-radius: 1rem;
  cursor: pointer;
  color: white;
  font-weight: bold;
}

.selected {
  background-color: #F9A08D;
}

.data {
  background-color: #EFF2F7;
  border-top-left-radius: 4rem;
  border-bottom-left-radius: 4rem;
  width: 100%;
  padding: 5rem;
  box-sizing: border-box;
  display: float;
}

.data img {
  width: 100%;
  border-radius: 1rem;
}

@media (max-width: 767.5px) {
  .two-color-blue-grey {
    background: none !important;
    background-color: #3E8FED !important;
  }

  .content {
    width: 100%;
    box-sizing: border-box;
    padding: 0;
  }

  .container {
    display: block;
  }

  .button {
    width: 100%;
    padding: 2rem;
    box-sizing: border-box;
  }

  .data {
    border-radius: 0;
    padding: 2rem;
  }
}
</style>