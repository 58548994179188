<template>
  <div class="content">
    <div class="feature">
      <div class="step">
        <Vue3Lottie :animation-data="listPresentationJSON" :height="150" />
        <p>{{ $t('work.step_1') }}</p>
      </div>
      <div class="step">
        <Vue3Lottie :animation-data="listDeveloperJSON" :height="150" />
        <p>{{ $t('work.step_2') }}</p>
      </div>
      <div class="step">
        <Vue3Lottie :animation-data="listTrainJSON" :height="150" />
        <p>{{ $t('work.step_3') }}</p>
      </div>
      <div class="step">
        <Vue3Lottie :animation-data="listIntegrationJSON" :height="150" />
        <p>{{ $t('work.step_4') }}</p>
      </div>
      <div class="step">
        <Vue3Lottie :animation-data="listGrowthJSON" :height="150" />
        <p>{{ $t('work.step_5') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { Vue3Lottie } from 'vue3-lottie'
import listDeveloperJSON from '../assets/lottie/list-developer.json'
import listGrowthJSON from '../assets/lottie/list-growth.json'
import listIntegrationJSON from '../assets/lottie/list-integration.json'
import listPresentationJSON from '../assets/lottie/list-presentation.json'
import listTrainJSON from '../assets/lottie/list-train.json'

export default {
  name: 'WorkComp',
  components: {
    Vue3Lottie
  },
  data() {
    return {
      listDeveloperJSON,
      listGrowthJSON,
      listIntegrationJSON,
      listPresentationJSON,
      listTrainJSON
    }
  }
}
</script>

<style scoped>
.feature {
  display: flex;
  padding: 5rem 0;
  font-weight: bold;
}
.step {
  flex-wrap: wrap;
  flex: 1;
  height: 100%;
  padding: 1rem;
}

.step p {
  text-align: center;
  font-size: 1.5rem;
}

@media (max-width: 767.5px) {
  .feature {
    display: block;
  }

  .step {
    display: block;
    flex: 0;
  }
}

</style>