<template>
  <div>
    <HeaderComp />
    <BannerComp />
    <PreambuleComp />
    <WorkComp />
    <ServiceComp />
    <PortfolioComp />
    <LinkComp />
    <FooterComp />
  </div>
</template>

<script>
import BannerComp from './components/BannerComp.vue';
import PreambuleComp from './components/PreambuleComp.vue';
import FooterComp from './components/FooterComp.vue';
import WorkComp from './components/WorkComp.vue';
import HeaderComp from './components/HeaderComp.vue';
import LinkComp from './components/LinkComp.vue';
import ServiceComp from './components/ServiceComp.vue';
import PortfolioComp from './components/PortfolioComp.vue';

export default {
  name: 'App',
  components: {
    BannerComp,
    PreambuleComp,
    FooterComp,
    WorkComp,
    HeaderComp,
    LinkComp,
    ServiceComp,
    PortfolioComp,
  },
  mounted() {
    document.title = "One Stop IT Solution | Stropie.id"
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  margin: 0;
}

#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  /* text-align: center; */
  /* font-family: "Lato"; */
  font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  color: #2c3e50;
  /* margin-top: 60px; */
  font-size: 1.65rem;
}

.content {
  margin: auto;
}

@media (max-width: 767.5px) {
  .content {
    padding: 0 2rem;
  }
}

@media (min-width: 992px) {
  .content {
    width: 960px;
  }
}

@media (min-width: 1200px) {
  .content {
    width: 1140px;
  }
}

@media (min-width: 1400px) {
  .content {
    width: 1320px;
  }
}

.text.blue-dark {
  color: #264a9e;
}

.text.blue-ex {
  color: #3E8FED;
}

.text.green {
  color: green;
}

.text.white {
  color: white;
}

.background.black {
  background-color: #000000;
}

.background.yellow {
  background-color: #fffd87;
}

.background.blue-ex {
  background-color: #3E8FED;
}

.background.blue-light {
  background-color: #88eaf7;
}

.background.blue-dark {
  background-color: #264a9e;
  color: #ffffff;
}

.background.two-color-blue-grey {
  background: linear-gradient(
    to right,
    #3E8FED 0%,
    #3E8FED 50%,
    #EFF2F7 50%,
    #EFF2F7 100%
  );
}

.bold {
  font-weight: 700;
}
</style>
