<template>
  <div class="background">
    <div class="content">
      <div class="banner-header">
        <div class="banner-text">
          <p class="text blue-dark bold smaller">{{ $t('sample.banner_skill') }}</p>
          <h1>{{ $t('sample.banner_text') }}</h1>
          <p>{{ $t('sample.banner_tagline') }}</p>
          <div class="btn-container">
            <a target="_blank" href="https://wa.me/628991905550?text=*Hello, Stropie*%0aI am (your name) (your message)" class="btn btn-primary background blue-ex">
              {{ $t('sample.banner_call') }}
            </a>
          </div>
        </div>
        <Vue3Lottie :animation-data="developmentJSON" :width="1200"/>
      </div>
    </div>
  </div>
</template>

<script>
import { Vue3Lottie } from 'vue3-lottie'
import developmentJSON from '../assets/lottie/development.json'

export default {
  name: 'BannerComp',
  components: {
    Vue3Lottie
  },
  data() {
    return {
      developmentJSON,
    }
  }
}
</script>

<style scoped>
.banner-header {
  display: flex;
  font-family: "Lato";
}

.banner-text {
  padding-bottom: 7rem;
  box-sizing: border-box;
  width: 100%;
}

h1 {
  font-size: 3.5rem;
  font-weight: 700;
  margin: 0;
  margin-bottom: 2rem;
  line-height: 4.1rem;
}

p {
  font-size: 2rem;
  padding: 1rem 0;
  margin: 0;
  margin-bottom: 1rem;
}

.smaller {
  font-size: 1.65rem;
}

@media (max-width: 767.5px) {
  .lottie-animation-container {
    display: none;
  }
}

.btn-container {
  margin-top: 4rem;
}
.btn {
  margin-top: 3rem;
  border-radius: 4px;
  padding: 1rem 1.75rem;
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
}
</style>