<template>
	<div class="content">
		<div class="greet">
			<h2>{{ $t('portfolio.greet_1') }}</h2>
			<p>{{ $t('portfolio.greet_2') }}</p>
		</div>
	</div>
	<div class="background blue-ex">
		<div class="content">
			<div class="row">
				<div class="showcase left">
					<div class="portfolio-item">
						<img src="../assets/img/portfolio-mobile.png">
						<h5>Foom</h5>
						<h3>Mobile Apps, Dashboard, E-Commerce</h3>
					</div>
					<div class="portfolio-item">
						<img src="../assets/img/portfolio-chocosoklat.png">
						<h5>Chocosoklat</h5>
						<h3>Live Streaming Server</h3>
					</div>
				</div>
				<div class="showcase right">
					<div class="portfolio-item">
						<img src="../assets/img/portfolio-web.png">
						<h5>Niaga</h5>
						<h3>Customer Portal, Web App, Management System</h3>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'PortfolioComp'
}
</script>
<style scoped>
.greet {
	padding: 2rem 5rem;
}

.row {
	display: flex;
	flex-wrap: wrap;
	padding: 3rem;
	color: white;
}

.showcase {
	flex: 0 0 auto;
	width: 50%;
}

.showcase.right {
	padding-top: 20rem;
}

.portfolio-item {
	margin: 0 3rem 9rem;
}

h5 {
	font-weight: normal;
	font-size: 1.5rem;
	padding: 0.5rem 0;
	border-bottom: 1px solid;
	margin: 1rem 0;
}

h3 {
	margin: 1rem 0;
	font-weight: bold;
	font-size: 2rem;
}

.col {
/*	display: flex;*/
	padding: 2rem;
/*	align-items: center;*/
/*	width: 50%;*/
	box-sizing: border-box;
}

img {
	width: 100%;
	height: auto;
	filter: grayscale(100%);
/*  transform: scale(.95);*/
  transition: all 1s ease;
}

img:hover {
  filter: none;
  transform: scale(1);
}

@media (max-width: 767.5px) {
	.greet {
		padding: 0;
	}
	
	.row {
		padding: 0;
	}

	.col {
		width: 100%;
		padding: 0;
	}

	.showcase {
		width: 100%;
	}

	.showcase.right {
		padding: 0;
	}

	.portfolio-item {
		margin: 0;
		margin-top: 4rem;
		margin-bottom: 4rem;
	}
}
</style>