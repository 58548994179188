<template>
  <div class="background blue-ex">
    <div class="content">
      <div class="footer">
        <p>© StroPIE {{ new Date().getFullYear() }}</p>
        <p class="parent-company">supported by <b>PT. Persada Indah Elektrindo</b></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterComp"
}
</script>

<style scoped>
.footer {
  /* display: flex; */
  padding: 2rem 0;
  color: white;
}

p {
  flex: 1;
  font-size: 2rem;
}

.parent-company {
  text-align: left;
  font-size: 1rem;
}

</style>