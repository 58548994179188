<template>
  <div class="navbar"
    :class="{ 'collapsed': !view.topOfPage }">
    <div class="content">
      <div class="header">
        <a href="/">
          <img src="../assets/logo.png">          
        </a>
        <div class="drop-lang">
          <CustomSelect 
            :options="['id', 'en']"
            :default="'id'"
            @input="$i18n.locale=$event"
            />
        </div>
      </div>
    </div>
  </div>
  <div class="spacer"></div>
</template>
<script>
import CustomSelect from "./widgets/CustomSelect.vue"
export default {
  name: 'HeaderComp',
  components: {
    CustomSelect
  },
  data () {
    return {
      view: {
        topOfPage: true
      }
    }
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll(){
      if(window.pageYOffset>0){
        if(this.view.topOfPage) this.view.topOfPage = false
      } else {
        if(!this.view.topOfPage) this.view.topOfPage = true
      }
    }
  },
}
</script>
<style scoped>
.navbar {
  width: 100%;
  background-color: white;
  position: fixed;
  z-index: 9999;
  transition: all .25s ease-out;
  padding: 3rem 0;
}

.navbar img {
  width: 200px;
  height: auto;
  transition: all .25s ease-out;
}

.navbar.collapsed {
  box-shadow: 0 0 5px rgba(0, 0, 0, .3);
  padding: 1rem 0;
}

.navbar.collapsed img {
  width: 150px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

.drop-lang {
  width: 6rem;
}

.spacer {
  min-height: 9rem;
/*  background-color: crimson;*/
}
</style>