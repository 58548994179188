<template>
  <div class="container">
    <div class="content">
      <p>{{ $t('link.greet') }}</p>
      <h5>Jl. Ketintang barat 2 no 6</h5>
      <h5>Surabaya, 60231</h5>
      <h5>+62 8991 90 5550</h5>
      <h5>stropie2024@gmail.com</h5>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LinkComp'
}
</script>
<style scoped>
.container {
  padding: 5rem 0;
  /* border-top: 1px solid #949494; */
}

p {
  padding-bottom: 3rem;
  font-size: 1.5rem;
  font-weight: 600;
}

h5 {
  font-size: 1.65rem;
  margin: 0;
  font-weight: normal;
  line-height: 2.2rem;
  font-family: "Lato";
}
</style>